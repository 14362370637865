/* Radio  */
.groupContainer {
  display: flex;
  flex-wrap: wrap;
}
.container {
  position: relative;
  display: inline-block;
}
.container:not(:last-child) {
  margin-right: 10px;
}

.radioInput {
  opacity: 0 !important;
  z-index: 0;
  position: absolute;
  bottom: -1px;
  left: 50%;
  height: 1px;
  width: 1px;
}
.radioInput :focus + .label {
  color: rgb(0, 95, 204);
  border-color: rgb(0, 95, 204);
}
.label {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.label:hover {
  border-color: #999;
}
.label.checked {
  color: rgb(0, 95, 204);
  border-color: rgb(0, 95, 204);
}
.label.disabled {
  opacity: 0.35;
  border-color: #ddd;
  cursor: not-allowed;
}

/* Radio  */
