.userWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 15px;
}

.avatar img {
  margin-right: 15px;
  border-radius: 4px;
}

.info p {
  margin-bottom: 0;
}

.id {
  opacity: .4;
}
.userInfo{
  display: flex;
  align-items: center;
}
.userInfo .tags {
  font-size: 14px;
  top: 2px;
  position: relative;
}
.userTable{
  margin-top: .5em;
}

