/* Radio  */
.groupContainer {
  display: flex;
  flex-wrap: wrap;
}
.container {
  position: relative;
  display: inline-block;
}
.container:not(:last-child) {
  margin-right: 10px;
}
