.textareaWrapper {
  position: relative;
}

.preview {
  position: absolute;
  right: 5px;
  bottom: 5px;
  border-radius: 4px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  background: rgba(0, 0, 0, 0.06);
  cursor: pointer;
}

.preview:hover {
  background: rgba(0, 0, 0, 0.15);
}
